import React, { useState, useCallback } from "react"

const mailerURL = "/api/sendgrid/mailer";

const ContactForm = () => {

  const [message, setMessage] = useState({
    hp: "",
    name: "",
    email: "",
    text: ""
  });

  const [formState, setFormState] = useState({
    sent: false,
    failed: false,
    submitDisabled: true,
    submitting: false,
    error: null
  });

  const updateMessage = useCallback((event) => {
    const name = event.target.getAttribute("name")
    setMessage(prevMessage => {
      return {
      ...prevMessage,
      [name]: event.target.value
      }
    });
  }, []);

  const updateFormState = useCallback((newState) => { 
    setFormState(prevState => { 
      return {
        ...prevState, 
        ...newState
      }
    }); 
  }, []);

  React.useEffect(() => {
    if (message.name && message.name.trim()!=="" && message.email && message.email.trim()!=="" && message.text && message.text.trim()!=="") {
      updateFormState({submitDisabled: false});
    } else {
      updateFormState({submitDisabled: true});
    }
  }, [message.name, message.email, message.text, updateFormState]);


  const sendMail = async event => {
    event.preventDefault()
    updateFormState({ submitting: true })
    const { hp, name, email, text } = message
 
    const response = await fetch(mailerURL, {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({ hp, name, email, text }).toString(),
    })
    if (response.status === 200) {
      updateFormState({
        sent: true,
        error: null,
        submitting: false,
      })
      setMessage({
        hp: "",
        name: "",
        email: "",
        text: ""
      })
    } else {
      const json = await response.json()
      updateFormState({
        error: json.error,
        failed: true,
        submitting: false,
      })
    }
  }

  return (
    <form action={mailerURL} method="POST" className="py-8 mb-4">
      <div className="hidden">
        <input type="text" id="hp" name="hp" onChange={event => updateMessage(event)} value={message.hp}/>
      </div>
      <div>
        <span className="uppercase text-sm font-bold">Uw naam *</span>
        <input id="name" name="name" onChange={event => updateMessage(event)} value={message.name} className="w-full font-title bg-dl-input border-b-2 border-dl-orange border-opacity-10 text-black mt-2 p-3 focus:outline-none focus:shadow-outline focus:border-opacity-40" type="text" placeholder=""/>
      </div>
      <div className="mt-8">
        <span className="uppercase text-sm font-bold">Uw e-mailadres *</span>
        <input id="email" name="email" onChange={event => updateMessage(event)} value={message.email} className="w-full font-title bg-dl-input border-b-2 border-dl-orange border-opacity-10 text-black mt-2 p-3 focus:outline-none focus:shadow-outline focus:border-opacity-40" type="email"/>
      </div>
      <div className="mt-8">
        <span className="uppercase text-sm font-bold">Uw bericht *</span>
        <textarea id="text" name="text" onChange={event => updateMessage(event)} value={message.text} className="w-full font-title h-32 bg-dl-input border-b-2 border-dl-orange border-opacity-10 text-black mt-2 p-3 focus:outline-none focus:shadow-outline focus:border-opacity-40"></textarea>
      </div>
      <div className="mt-8">
        <button 
          type="submit" 
          onClick={sendMail}
          disabled={ formState.submitting || formState.submitDisabled }
          className="button bg-dl-orange hover:bg-black text-white mr-2 w-full focus:outline-none focus:shadow-outline">
          Verstuur
        </button>
      </div>
      {formState.sent && <p className="text-green-700 text-sm">Bericht verzonden. We nemen weldra contact met u op.</p>}
      {formState.failed && <p className="text-red-700 text-sm">Bericht niet verzonden. Controleer of alle velden correct ingevuld zijn en probeer het nogmaals.</p>}
    </form>
  )
};

export default ContactForm;